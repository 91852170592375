.login-wrap {
  .footer {
    display: block;
    margin: 20px auto;
    text-align: center;
    width: 300px;

    .footer-item {
      &:not(:first-child):before {
        content: '|';
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
}
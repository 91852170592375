$primary: #5588a3; //#6571ff;
$primaryLight: lighten($primary, 20%);
$primaryDark: darken($primary, 20%);
$error: #ff3366;
$errorDark: darken($error, 20%);
$inputHelp: gray;
$infoHelp: #7987a1;
$success: #05a34a; //#66d1d1;
$successDark: darken($success, 10%);
$successLight: lighten($success, 10%);
$warning: #fbbc06;
$warningDark: darken($warning, 20%);

$mobileResolution: 425px;

/**
 * Colors
 */
.color-orange {
  color: $warning;

  &:hover {
    color: darken($warning, 20%)
  }
}

.color-primary {
  color: $primary;

  &:hover {
    color: $primaryLight;
  }
}
@keyframes flicker {
  0%, 100% {
    color: #888;
  }
  50% {
    color: $primary;
  }
}

.flicker-animation {
  cursor: default;
  display: inline-block;
  animation: flicker 2s infinite;
  color: #888;
}
@import "vars";

.medicharge-acl-introduction {
  padding: 25px;
  background-color: rgba($primary, .1);
  border: 1px solid $primary;
  border-radius: 5px;
}

.details-button {
  cursor: pointer;

  &:hover {
    color: $primaryDark;
  }
}


.no-documentation {
  color: #cdcdcd;
  font-size: 14px;
  padding: 0 !important;
}

table {
  td:first-child {
    vertical-align: top;
    min-width: 150px;
  }

  td {
    img.acl_permission_img {
      max-width: 100%;
    }
  }
}

.acl-tab-navigation {
  vertical-align: top;
  margin-top: 50px;

  .navigation {
    vertical-align: top;
    font-size: 14px;
    height: 30px;
    padding: 3px 0 0 0;
    cursor: pointer;
    border-radius: 5px;

    &.active {
      color: $primary;
      font-weight: bold;
    }

    &:hover {
      color: $primary;
    }
  }
}

.acl-container {
  vertical-align: top;
  margin: 40px 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  overflow: hidden;

  .acl-content {
    width: 100%;
    text-align: left;
    display: inline-block;
  }

  .header {
    //padding-left: 251px;
    //border-bottom: 1px solid rgba(224, 224, 224, 1);
    background: $primary;

    .column {
      border-right: 1px solid $primary !important;
    }

    .cell {
      text-align: center;
      color: #fff !important;
      border: none !important;

      &.section-name {
        width: 250px;
        text-align: left;
        font-weight: bold;
      }
    }

    &.fixed {
      display: none;
      position: fixed;
      top: 55px;
      background: $primary;
      //box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      z-index: 999;

      .cell {
        color: #fff !important;
        padding: 18px 14px 10px 14px;
        border-bottom-color: transparent;
      }
    }

    &.display {
      display: block;
    }
  }

  .permissions {
    .header {
      .column:first-child {
        width: 250px;
      }
    }
    .column {
      width: calc((100% - 250px)/6);

        .cell {
          &:not(.perm-enabled) {
            label {
              opacity: 0.2;
              cursor: default;

              .__react_component_tooltip {
                display: none !important;

              }
            }

            label:first-child {
              opacity: 1;
              cursor: pointer;
            }
          }
        }



      .cell:first-child {
        padding: 14px;
        height: 50px;
      }

      &.acl-row.th {
        width: 250px;
      }
    }
  }

  .save-row {
    margin: 15px 0 0 0;
    text-align: right;
  }

  .column {
    vertical-align: top;
    font-size: 0;
    display: inline-block;
    border-right: 1px solid rgba(224, 224, 224, 1);

    &.th .cell {
      cursor: default;
      color: $primary;
    }

    &:last-child {
      border-right: none;
    }

    .cell {
      position: relative;
      vertical-align: top;
      font-size: 16px;
      height: 50px;
      padding: 14px;
      //min-width: 180px;
      //width: 20%;
      border-bottom: 1px solid rgba(224, 224, 224, 1);

      .pay-contract-option {
        position: absolute;
        top: 17px;
        left: 12px;
        fill: $primary;
        font-size: 20px;
      }

      &:last-child {
        border-bottom: 0;
      }

      &.label {
        min-width: 250px;
        width: 250px;
      }

      &.group {
        height: 80px;
        padding: 50px 0 0 0;
        position: relative;
        font-weight: bold;
        color: #444;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: -1px;
          width: 1px;
          bottom: 0;
          background: #fff;
        }
      }

      &.sub {
        span:first-child {
          display: inline-block;
          padding: 0 0 0 25px;
        }

        span:last-child {
          padding: 0 0 0 10px;
        }
      }

      &.th {
        cursor: default;
        color: $primary;
      }

      label {
        margin: -20px 0 0 0;
        cursor: pointer;

        svg {
          width: 38px;
          height: 35px;

          &.own, &.manager {
            height: 28px;

            &.disabled {
              color: rgba(224, 224, 224, 1);
            }
          }

          &.hidden {
            cursor: default;
            opacity: 0.3;
          }

          &.check {
            color: $success;
          }

          &.forbidden {
            color: $error;
          }
        }
      }
    }
  }
}

.require-options {
  padding: 0;
  list-style-type: disc;
  margin: 0;

  .nothing {
    list-style-type: none;
  }
}
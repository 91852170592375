@import "./../vars";

.paid-by-other {
  position: relative;
  * {
    opacity: 0.8;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0
  }
}

.contract-take-over {
  text-align: center;

  .contract-take-over-message {
    margin: 45px 0 30px 0;
  }

  .contract-take-over-submit {
    margin: 15px 0 0 0;
  }
}

//.contract-shop {
//  display: block;
//  padding: 10px 15px !important;
//  font-size: 24px;
//  text-transform: uppercase;
//  width: 100%;
//}

.contract-shop {
  padding: 16px 0;

  svg {
    font-size: 18px;
    vertical-align: top;
  }
}

.contract-sum-price-row {
  .part-price {
    color: #5588a3;
    font-size: 14px;
  }

  .contract-item-price {
    border-top: 1px solid #e9ecef;
  }

  .final-price {
    font-size: 20px;
  }
}

.price-invoice {
  div {
    color: #444;
    font-weight: bold;
  }

  div:last-child {
    text-align: right;
  }
}

.sub-total-invoice {
  div {
    color: $warning;
    font-size: 18px;
    font-weight: bold;
  }
}

.total-invoice {
  div {
    color: $primary;
    font-size: 18px;
    font-weight: bold;
  }
}

.cancel-info {
  color: $error;
  float: right;
  font-size: 10px;
  cursor: default;
}

.contract-item-description {
  color: $inputHelp;
  font-size: 12px;
  padding: 8px 0 0 0;
  white-space: break-spaces;
  cursor: default;
}

.contract-period-change {
  margin: 0 0 0 10px;
  color: $primary;
  cursor: default;
  font-size: 12px;
}

.sum-price-row.contract-sum-price-row.shop-readonly {
  opacity: 0.4;
  * {
    cursor: default;
  }
}

.shop-container {
  position: relative;
  z-index: 10;

  .shop-blocker-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .shop-readonly {
    opacity: 0.4;
    * {
      cursor: default;
    }
  }
}

.contract-notice-star {
  color: #7a7a7a;
  font-size: 12px;
}

.contract-notice {
  color: #7a7a7a;
  font-size: 12px;
}

.contract-pricing {
  //background-image: linear-gradient(to right top, lighten($primaryLight, 10%), lighten($primaryLight, 30%));
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 6px;

  table {
    td:first-child {
      min-width: 0;
    }
  }

  .contract-feature-enabled {
    &.contract-pricing-disabled {
      opacity: 0.5;
    }

    svg, p {
      color: $success !important;
      vertical-align: top;
      margin-top: 2px;
    }
  }

  .contract-feature-disabled {
    svg, p {
      color: $error !important;
      vertical-align: top;
      margin-top: 2px;
    }
  }

  .contract-price {
    font-weight: bold;
    color: #000;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .contract-type {
    color: #000;
    font-size: 20px;
    font-weight: bold;
  }
}
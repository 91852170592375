@import "../vars";

.short-notification-container {
  position: relative;
  margin: 5px 15px 0 0;

  .remove-notification {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 17px;
    height: 17px;
    color: $error !important;

    &:hover {
      color: $errorDark !important;
    }
  }

  .bubble-count {
    position: absolute;
    background-color: $primary;
    color: #fff;
    top: -4px;
    right: -8px;
    border-radius: 5px;
    padding: 1px 5px 0 5px;
    font-size: 10px;
    cursor: pointer;
  }

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: $inputHelp;
  }

  .short-notification-box {
    width: 400px !important;
    top: 34px !important;
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 20rem;
    padding: 15px 0 0 0 !important;
    margin: 0;
    font-size: 0.875rem;
    color: #000;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f2f4f9;
    border-radius: 0.25rem;
    left: -17rem;
    //box-shadow: 0 5px 10px 0 rgba(183, 192, 206, 20%);

    .notification-list {
      max-height: 400px;
      overflow: auto;
      overflow-x: hidden;
    }

    .filter-row {
      .filter-check-box {
        input {
          width: 15px;
          height: 15px;
        }

        label {
          font-size: 12px;
          vertical-align: top;
        }
      }
    }

    .notification-row {
      border-bottom: 1px solid #f2f4f9;

      &:last-child {
        border-bottom-color: transparent;
      }
    }

    .notification {
      //background: rgba(101,113,255,.1);
      //border-radius: 5px;
      padding: 5px 10px;
      display: block;

      .title {
        color: $primary;
        cursor: pointer;
        font-size: 14px;
      }

      .date {
        float: right;
        color: $inputHelp;
        display: inline-block;
        font-size: 12px;
        margin: 0;
        cursor: pointer;
      }

      .info {
        color: $inputHelp;
        font-size: 12px;
        padding: 0 0 0 15px;
      }

      &:hover {
        background-color: rgba(102,209,209,.1);
      }
    }
  }

  &:hover {
    svg {
      color: $primary;
    }

    .short-notification-box {
      display: block;
    }
  }
}

.notification-sound {
  vertical-align: middle;
  width: calc(100% - 25px);
}

.notification-icon {
  width: 20px !important;
  height: 20px !important;
}

.sound-position {
  width: 100px;
  position: absolute;
  right: 15px;
  top: 12px;
}

.push-notification {
  margin: 0 15px 9px 15px;

  .push-notification-header {
    color: $primary;
    cursor: pointer;
    font-size: 14px;
  }

  .push-notification-body {
    color: $inputHelp;
    font-size: 12px;

    .notify-medicine-table {
      width: 100%;

      th {
        border-bottom: 1px solid $inputHelp;
        font-weight: bold;
      }

      td {
        padding: 2px 5px;
      }
    }
  }
}
@import "../vars";

label.card {
  cursor: pointer;
  margin: 0 0 20px 0;

  .card-body {
    font-size: 0;

    & > * {
      font-size: 16px;
      vertical-align: top;
    }

    .card-help-container {
      position: absolute;
      top: 3px;
      left: 5px;

      .card-help-icon {
        font-size: 20px;
        color: lightgray;
      }

      .info-row {
        margin: 0 0 10px
      }

      .info-row:last-child {
        margin: 0;
      }

      .info-popover {
        display: none;
        background-color: #fff;
        position: absolute;
        top: -15px;
        left: 30px;
        .card-body {
          padding: 5px 10px;
        }

        //border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

        .info-headline {
          font-size: 12px;
          color: $primary
        }

        .info-content {
          width: 300px;
          font-size: 10px;
          color: $infoHelp;
          text-align: justify;
          //white-space: nowrap;
        }
      }

      &:hover {
        .info-popover {
          display: block;
        }
        .card-help-icon {
          color: $primary;
        }
      }
    }

    .card-icon-container {
      width: 25%;
      display: inline-block;

      img {
        width: 100%;
      }

      svg {
        width: 90px;
        height: 40px;
        margin: 4px 0 0 0;
        color: lightgray;
      }

      .icon-no-image {
        width: 100%;
        font-size: 40px;
        color: $infoHelp;
      }
    }

    .card-info-container {
      width: 75%;
      display: inline-block;
      padding: 0 0 0 20px;

      .card-description {
        color: $infoHelp;
        margin: 5px 0 0 0;
        font-size: 12px;
        line-height: 13px;
        display: inline-block;
        height: 25px;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 10px 0 $primaryLight;
    -webkit-box-shadow: 0 0 10px 0 $primaryLight;
    -moz-box-shadow: 0 0 10px 0 $primaryLight;
    -ms-box-shadow: 0 0 10px 0 $primaryLight;

    .card-icon-container {
      svg {
        color: $primaryLight;
      }
    }
  }
}

input[type="radio"].card-input, input[type="checkbox"].card-input {
  display: none;

  &:checked {
    + label.card {
      border: 1px solid $primaryLight;

      .card-icon-container {
        svg {
          color: $primary;
        }
      }
    }
  }
}

@media (max-width: $mobileResolution) {
  .card-info-container {
    h5 {
      margin: 0;
      padding-left: 0;
    }
  }

  input[type="radio"].card-input, input[type="checkbox"].card-input {
      + label.card {
        border: 1px solid lightgray;
        padding: 5px 5px 0 0;
      }
  }
}

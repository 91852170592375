@import "../vars";

.request-view-container {
  position: relative;
  margin: 5px 5px 0 0;

  svg {
    color: #cdcdcd;
    cursor: pointer;
  }

  &:hover {
    svg {
      color: $primary;
    }
  }

  .performance-popover {
    display: none;
    background: #fff;
    border-radius: 4px;
    padding: 5px 5px 5px 10px;
    position: absolute;
    right: -15px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;

    tr {
      th {
        font-weight: bold;
        padding: 0 10px 0 0;
      }

      td {
        white-space: nowrap;
        padding: 0 10px 5px 0;
      }

      td.post {
        color: $success;
      }

      td.get {
        color: $primary;
      }

      td.put, td.patch {
        color: $warning;
      }

      td.delete {
        color: $infoHelp;
      }
    }
  }

  &:hover {
    .performance-popover {
      display: block;
    }
  }
}
nav.sidebar {
  margin: 0;
  visibility: visible !important;

    .nav-item {
      svg {
        color: #444 !important;
      }

      svg.fill {
        fill: #444 !important;
      }
    }

  .nav-item {
    //padding: 0 0 0 17px;
    .nav-link {
      height: 24px;
    }

    .old-view {
      position: absolute;
      right: 0;
      z-index: 9999;
      top: 9px;
      color: #686868;
      font-size: 10px;
      text-decoration: none;
    }

    &:hover {
      .nav-link {
        color: $primary !important;

        svg {
          color: $primary !important;
        }

        svg.fill {
          fill: $primary !important;
        }
      }
    }
  }

  .nav-category {
    padding: 0;
    vertical-align: top;
    font-size: 12px !important;
    cursor: default;
    color: $primary !important;

    &:before {
      background-color: $primary !important;
    }

    svg {
      vertical-align: top;
      margin: 0px 5px 0 0;
      font-size: 14px !important;
      color: $primary !important;
    }
  }
}

.sidebar-brand {
  cursor: default;
  color: $primary !important;

  span {
    color: $primaryLight !important;
  }
}

.logo {
  max-height: 60px;
}
@import "../vars";



h1 {
      font-size: 30px;
      padding: 10px 15px 5px 15px;
      margin: -12px -12px 11px -12px;
}

h2 {
      font-size: 26px;
      padding: 10px 15px 5px 15px;
      margin: -12px -12px 11px -12px;
}

h3 {
      font-size: 22px;
      padding: 10px 15px 5px 15px;
      margin: -12px -12px 11px -12px;
}

h4 {
      font-size: 18px;
      padding: 10px 15px 5px 15px;
      margin: -12px -12px 11px -12px;
}

h5 {
      font-size: 14px;
      padding: 3px 15px 2px 15px;
      margin: -12px -12px 11px -12px;
}

h6 {
      font-size: 12px;
      padding: 10px 15px 5px 15px;
      margin: -12px -12px 11px -12px;
}

.content-headline {
  color: $primary;
  border-bottom: 1px solid $primary;
  cursor: default;

  &.bubble {
    border: 1px solid $primary;
    border-radius: 25px;
  }

  &.none {
    border: 0;
  }

  &.strong {
    font-weight: bold;
    border: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  cursor: default;
  text-transform: capitalize;

  + .text-muted {
    cursor: default;
  }

  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }
}

.headline-color {
  color: $primary;
}

.headline {
  color: $primary;
  font-size: 18px;
  border-bottom: 1px solid $primary;
  //background: $primary;
  //border-radius: 5px;
  padding: 10px 15px 5px 15px;
  //color: #fff;
  //font-weight: bold;
  margin: -12px -12px 11px -12px;
  cursor: default;
}
@import "./../vars";

.cart-container {
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0 15px 0 0;

  .cart-amount {
    position: absolute;
    top: -10px;
    right: 6px;
    width: 16px;
    height: 16px;
    font-size: 12px;
    color: #fff;
    z-index: 9;
    text-align: center;
    background: $success;
    border-radius: 50%;
  }

  .checkout-cart {
    position: relative;
    color: $success;
    z-index: 10;
    width: 30px;
    height: 30px;
  }
}

.shop-item-list {
  padding-right: 15px;
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: auto;

  table {
    td {
      span {
        line-height: 29px;
      }
    }
  }

  .in-cart {
    border: 3px solid $success;
    border-radius: 50%;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 24px;
    color: $success;
    font-weight: bold;
    vertical-align: top;
    cursor: default;
  }

.remove-from-cart {
  color: $error;
  font-size: 22px;
  margin: 0 0 0 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    color: $errorDark;
  }
}

  button {
    svg {
      margin-top: -5px !important;
      font-size: 16px;
    }
  }
}

.shop-footer {
  background: #fff;
  z-index: 90000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 10px;
  border-top: 1px solid #e9ecef;

  .checkout {
    text-align: right;
  }
}

.checkout-headline {
  color: #444;
  font-size: 36px;
}

.checkout-sub-headline {
  color: $infoHelp;
  text-transform: none;

}

.shop-checkout {
  max-height: 620px;
  overflow: auto;
  overflow-x: hidden;
}

.shop-cart-actions {
  position: relative;
  top: -2px;
  height: 20px;
  width: 100px;
  text-align: right;
  display: inline-block;
}

.checkout-section {
  position: absolute;
  left: 10px;
  right: 40px;
  bottom: 20px;
}

.sum-price-row {
  padding-right: 12px;

  .final-price {
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    color: $primary;
    font-size: 18px;

    div {
      border-bottom: 1px solid $primary;
    }
  }

  .price {
    text-align: right;
  }
}

.buy-cart {

  button {
    float: right;
  }
}
.modal-content-box {
  z-index: 10000 !important;

  .rodal-dialog {
    z-index: 110000;
    overflow: auto;

    .header {
      color: #555;
      padding-left: 0;
      background-color: transparent;
      font-size: 16px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e9e9e9;
    }

    .body {
      padding: 10px 0 0 0;
      color: #555;
    }
  }
}

.modal-margin {
  .rodal-dialog {
    margin: 30px auto;
  }
}


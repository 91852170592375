@import "../vars";

.navbar {
  margin: 0;
   width: calc(100% - 240px);
   height: 60px;
   background: #fff;
   border-bottom: 1px solid #e9ecef;
   display: flex;
   align-items: center;
   padding: 0;
   position: fixed;
   right: 0;
   left: 240px;
   z-index: 978;
   box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
   transition: width 0.1s ease, left 0.1s ease;

  /**
   * TODO: Hide unused searchbar
   */
  .search-form {
    opacity: 0;
    cursor: default;
  }

  .dropdown {
    .dropdown-menu {
      top: 10px;
      right: -10px;
      display: none;
    }

    &:hover {
      .dropdown-menu {
        display: block;

        .dropdown-item {
          &:hover {
            cursor: pointer;
            color: #fff;
            background-color: $primary;

            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
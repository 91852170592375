@import "../vars";

.tooltip-clipboard {
  margin-top: -20px;
  float: right;
  position: relative;
  z-index: 100;
}

.clipboard {
  font-size: 16px;
  margin: 0 0 0 10px;
  cursor: pointer;
  color: $primary;

  &:hover {
    color: $primaryDark
  }
}
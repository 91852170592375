@import "../../vars";

.password-summary-icon {
  vertical-align: top;
  margin: 0 5px;
  cursor: pointer;
  color: $inputHelp;
  font-size: 16px;

  &:hover {
    color: $primary;
  }
}
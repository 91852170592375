@import "../vars";

small.optional-field {
  color: $inputHelp;
  cursor: default;
}

.submit-btn {
  margin: 0 0 0 5px;
}

.step-progress {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;

  .step {
    position: relative;
    text-align: center;
    list-style-type: none;
    font-size: 15px;
    float: left;
    font-weight: 400;

    p {
      text-align: center;
      svg {
        font-size: 30px;
        margin: 10px 0;
      }
    }

    &:before {
      content: '';
      width: 40%;
      height: 2px;
      background: lightgray;
      position: absolute;
      left: 0;
      top: 25px;
      z-index: 0
    }

    &:after {
      content: '';
      width: 40%;
      height: 2px;
      background: lightgray;
      position: absolute;
      left: 60%;
      top: 25px;
      z-index: 0
    }

    &.prev-active {
      color: lighten($primary, 20);
      cursor: pointer;

      &:after, &:before {
        background: lighten($primary, 20);
      }
    }

    &.active {
      color: $primary;
      cursor: default;

      &:after, &:before {
        background: $primary
      }
    }
  }
}

@media (max-width: $mobileResolution) {
  .dialog-context {
    .step-progress {
      display: none;

    }

    .dialog-container {
      .card {
        background-color: transparent;
        box-shadow: none;
        border: none;

        .card-body {
          padding: 0;
        }
      }
    }

    button {
      text-indent: 0;
      width: auto;
    }

  }
}
@import "vars";
@import "components/datepicker";

//TODO remove v3 class from html and scss if system upgrade final to v3 version
body {
  > iframe {
    display: none !important;
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.system-change-alert {
  margin: 10px 10px 15px 10px;
}

.system-change-timer {
  > span {
    margin-top: 8px;
    display: inline-block;
  }

  .timer-value {
    font-size: 20px;
    font-weight: bold;
  }
}

.spin {
  color: $primary;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.sidebar-folded {
  .navbar {
    width: calc(100% - 70px) !important;
    left: 70px !important;
  }
}

.Toastify__toast-container {
    z-index: 99999 !important;
}

.push-notification {
  .Toastify__toast-body {
    margin: 0 15px;

    .push-notification-time{
      float: right;
      color: $inputHelp;
      margin: 3px 0 0 0;
      font-weight: normal;
      font-size: 10px;
    }

    .push-notification-header {
      margin: 0 0 7px -5px;
      padding: 0 0 2px 5px;
      color: $primary;
      cursor: pointer;
      font-size: 14px;
      border-bottom: 1px solid $primary;
    }

    .push-notification-body {
      color: $inputHelp;
      font-size: 12px;

      .notify-medicine-table {
        width: 100%;

        th {
          border-bottom: 1px solid $inputHelp;
          font-weight: bold;
          text-align: left;
        }

        td {
          padding: 2px 5px;
        }
      }
    }
  }
}

.v3 {
  //all: unset;
  //
  //* {
  //  all: unset;
  //}

  /* width */
  *::-webkit-scrollbar {
    margin: 10px 0;
    width: 10px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
    overflow: hidden;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 15px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primaryDark;
  }

  @import "color";
  @import 'core';
  @import 'spacing';
  @import 'style';
  @import "label";
  @import "mobile";
  @import "form/task";

  .row {
    position: relative;
  }

  .simple-item-listing {
    margin: 0;
    list-style-type: none;
    padding: 0;

    li {
      padding: 0;
      margin: 0;
    }
  }

  @import "topmenu";
  @import "userMenu";
  @import "masquerade";
  @import "content";

  @import "components/inputPasswordChange";
  @import "components/button";
  @import "components/dialog";
  @import "components/input";
  @import "components/select";
  @import "components/checkboxCard";
  @import "components/filterAbleGrid";
  @import "components/card";
  @import "components/table";
  @import "components/buttonDelete";
  @import "components/chip";
  @import "components/content";
  @import "components/link";
  @import "components/requestView";
  @import "components/checkbox";
  @import "components/new";
  @import "components/clipboard";
  @import "components/headline";
  @import "components/sidebar";
  @import "components/lastChange";
  @import "components/footer";
  @import "components/headline";
  @import "components/communication";
  @import "components/action";
  @import "components/icon";
  @import "components/navbar";
  @import "components/shortNotifications";
  @import "components/alert";
  @import "components/tooltip";
  @import "components/productCard";
  @import "components/shop";
  @import "components/modal";
  @import "components/contractRequired";
  @import "components/flicker";

  @import "list/userList";
  @import "list/pickupList";

  @import "form/user/summary";
  @import "form/user/user";
  @import "form/contract";
  @import "form/task";
  @import "acl";
  @import "margin";
}

@import "components/requestView";
.delete-opt-in-container {
  background: rgba(0, 0, 0, .4);
  grid-template-columns: auto minmax(0, 1fr) auto;
  display: grid;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  grid-template-areas:
        "top-start top top-end"
        "center-start center center-end"
        "bottom-start bottom-center bottom-end"
        "gap gap gap";
  grid-template-rows: auto auto auto .625em;
  height: 100%;
  padding: .625em .625em 0;
  overflow-x: hidden;
  transition: background-color .1s;

  .delete-opt-in-popover {
    display: grid;
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    justify-self: center;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0,100%);
    width: 32em;
    max-width: 100%;
    padding: 0 0 1.25em;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #545454;
    font-family: inherit;

    .delete-opt-in-icon-container {
      display: flex;
      position: relative;
      box-sizing: content-box;
      justify-content: center;
      width: 5em;
      height: 5em;
      margin: 2.5em auto .6em;
      border: .25em solid #facea8;
      color: #f8bb86;
      border-radius: 50%;
      font-family: inherit;
      line-height: 5em;
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .delete-opt-in-icon {
        display: flex;
        align-items: center;
        font-size: 3.75em;
      }
    }

    .delete-opt-in-title {
      position: relative;
      max-width: 100%;
      margin: 0;
      padding: .8em 1em 0;
      text-align: center;
      text-transform: none;
      word-wrap: break-word;
      font-size: 25px;
      line-height: 1;
      font-weight: 500;
      color: #000;
      cursor: default;
    }

    .delete-opt-in-description {
      z-index: 1;
      justify-content: center;
      margin: 1em 1.6em .3em;
      padding: 0;
      overflow: auto;
      line-height: normal;
      text-align: center;
      word-wrap: break-word;
      word-break: break-word;
      font-size: .875rem;
      color: #7987a1;
      font-weight: initial;
      margin-top: 11px;
      text-decoration: none;
      cursor: default;
    }

    .delete-opt-in-input {
      justify-content: center;
      margin: 0 1.6em .3em;
    }

    .delete-opt-in-actions {
      display: flex;
      z-index: 1;
      box-sizing: border-box;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 1.25em auto 0;
      padding: 0;
    }
  }

  small.help {
    display: block;
    text-align: left;
    cursor: default;
    font-size: 13px;
    margin: 3px 0 0 5px;

    svg {
      vertical-align: top;
      margin: 1px 5px 0 0;
      font-size: 13px;
    }
  }
}
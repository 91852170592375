@import './colors';

.app {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    padding-left: 240px;
    padding-top: 56px;
    transition: all 300ms ease;

    &.fullwidth {
        padding-left: 50px;
        .top-bar {
            width: calc(100% - 50px);
        }
    }

    .cursor {
        cursor: pointer;
    }

    .mg-t-24 {
        margin-top: 24px;
    }

    .mg-r-16 {
        margin-right: 16px;
    }

    .MuiBreadcrumbs-ol {
        a {
            color: inherit;
        }
    }

    nav ~ div.MuiGrid-root {
        width: calc(100% + 8px);
    }
}

.ck-editor__editable {
    min-height: 200px;
}

a {
    color: $blue1;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

ul {
    list-style: none;
}

.right {
    text-align: right;
}

.clear {
    &::after {
        clear: both;
        content: '';
        display: block;
    }
}

@media only screen and (min-width: 601px) {
    .app {
        padding-top: 64px;
    }
}

@media only screen and (max-width: 1200px) {
    .app {
        padding-left: 70px;
    }
}

@media only screen and (max-width: 540px) {
    .app {
        padding-left: 0;
    }
}

@import "vars";

@media (max-width: $mobileResolution) {
  .mobile-no-mt {
    margin-top: 0 !important;
  }

  .mt-4 {
    margin-top: 0.5rem !important;
  }

  .mb-3 {
    margin-bottom: 0.3rem !important;
  }

  .mt-xs-3 {
    margin-top: 16px;
  }

  .mobile-fullwidth {
    width: 100%;
    max-width: 100%;
  }
}
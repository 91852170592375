@import "../vars";

.chat-wrapper {
  height: auto !important;
  margin: 0 0 15px 0;
  .chat-body {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.message-item {
  width: 100%;
  max-width: 100% !important;
  .message, .document, .fax, .faxresponse, .email  {
    float: right;
    margin: 0 10px 0 0;

    .avatar {
      float: right;
    }

    .time {
      margin: -4px 4px 0 0;
      display: block;
      cursor: default;
      float: right;
    }

    .bubble {
      display: inline-block;
      border-radius: 5px 0 5px 5px;
      white-space: break-spaces;
    }
  }

  .message {
    .author {
      display: none;
    }
    .files {
      margin: 0;
      padding: 5px 0 0 5px;
      float: right;

      li {
        svg {
          width: 25px;
          height: 25px;
          color: $inputHelp;
          fill: $inputHelp;
          cursor: pointer;
        }

        &:hover {
          svg {
            color: $primary;
            fill: $primary;
          }
        }
      }
    }
  }

  .message .bubble {
    background: rgba(101,113,255,.1);

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: -10px;
      top: 0;
      border-top: 5px solid rgba(101,113,255,.1);
      border-bottom: 5px solid transparent;
      border-left: 5px solid rgba(101,113,255,.1);
      border-right: 5px solid transparent;
    }
  }
  .fax, .faxresponse {
    .bubble {
      background-color: rgba(251,188,6,.1);

      &:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: -10px;
        top: 0;
        border-top: 5px solid rgba(251,188,6,.1) !important;
        border-bottom: 5px solid transparent;
        border-left: 5px solid rgba(251,188,6,.1) !important;
        border-right: 5px solid transparent;
      }
    }

    .avatar {
      padding: 4px 0 0 8px;

      svg {
        width: 20px;
      }
    }
  }

  .document {
    .bubble {
      background-color: rgba(102,209,209,.1);

      &:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: -10px;
        top: 0;
        border-top: 5px solid rgba(102,209,209,.1);
        border-bottom: 5px solid transparent;
        border-left: 5px solid rgba(102,209,209,.1);
        border-right: 5px solid transparent;
      }
    }

    .avatar {
      padding: 4px 0 0 8px;

      svg {
        width: 25px;
      }
    }
  }

  .email {
    .bubble {
      background-color: rgba(121,135,161,.1);

      &:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: -10px;
        top: 0;
        border-top: 5px solid rgba(121,135,161,.1);
        border-bottom: 5px solid transparent;
        border-left: 5px solid rgba(121,135,161,.1);
        border-right: 5px solid transparent;
      }
    }

    .avatar {
      padding: 4px 0 0 8px;

      svg {
        width: 25px;
      }
    }
  }

  .self {
    .time {
      margin: -4px 0 0 4px;
      display: block;
      cursor: default;
      float: left;
    }

    .bubble {
      background: rgba(102,209,209,.1);
      border-radius: 0 5px 5px;

      &:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        left: -10px;
        top: 0;
        border-top: 5px solid rgba(102,209,209,.1);
        border-bottom: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid rgba(102,209,209,.1);
      }
    }
  }

  .content {
    max-width: calc(100% - 80px);
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 10px;
    float: right;

    &.self {
      float: left
    }

    .message-content {
      display: inline-block;
      margin: 0 0 0 10px;
    }
  }

  .avatar {
    text-align: left;
    vertical-align: top;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid $primaryLight;

    padding: 2px 0 0 4px;

    svg {
      float: left;
      height: 30px;
      width: 30px;
      color: $primaryLight;
      fill: $primaryLight;
    }
  }
}

@media (max-width: 425px) {
  .row {
      &.chat-wrapper {
        height: calc(100% - 136px) !important;
        .chat-content {
          height: 100%
        }
        .chat-body {
          height: calc(100% - 40px);
          max-height: calc(100% - 40px);
        }
      }
  }

  .message-item {
    .message {
      .author {
        margin: -4px 0 0 4px;
        display: block;
        cursor: default;
        float: left;
      }
    }
    .content {
      max-width: 100%;

      &.self {
        .author {
          float: right;
          margin: -4px 4px 0 0;
        }
      }

      .avatar {
        display: none;
      }
    }
  }

  .form-new-message {
    button {
      text-indent: 0;
      width: 100%;
      margin: 10px 0 0 0;
    }
  }

  .chat-content {
    padding: 0;
    .messages {
      padding: 0 !important;

      .content-headline {
        text-align: center;
      }

     .message-item {
       margin: 0 0 20px 0;
        > div {
         padding: 0;

          .content {
            margin: 0;

            .message-content {
              margin: 0;
            }
          }
       }
      }
    }
  }
}
@import "./../vars";

.product-disabled {
  opacity: 0.7;
}

.product-card {
  border: 1px solid $primary;
  margin-bottom: 25px;

  .card-body {
    padding-bottom: 58px;
  }

  .product-price {
    color: $primary;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  svg {
    color: $primary !important;
  }

  .icon-md {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  .add-to-cart {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
  }

  .product-price {
    color: $primary;
    height: 20px !important;
    font-size: 18px;
  }


  .product-interval {
    font-weight: 400 !important;
    font-size: 12px;
  }
}
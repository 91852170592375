@import './colors';

body {
  > iframe {
    display: none !important;
  }
}

.app {
  .MuiCardContent-root > h1, .MuiCardContent-root > div > h1 {
    background-color: $blue3;
    color: white;
    padding: 8px 16px;
    text-transform: uppercase;
    border-radius: 4px;
  }

  .MuiSpeedDial-fab {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    border-radius: 50%;
    box-shadow: none;
    background: transparent;
    width: initial;
    height: initial;

    &:hover {
      box-shadow: none;
      background: rgba(0, 0, 0, 0.04);
    }
  }

  .input-field.col {
    float: none;
  }

  .btn > svg {
    font-size: 1.3rem;
    line-height: inherit;
    margin-right: 12px;
    margin-top: 9px;
  }

  .filterable, .responsiveTable tbody td.pivoted {
    cursor: pointer;
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    .sort-icon {
      display: none;
      margin-left: 8px;
      margin-top: 5px;
      position: absolute;
    }

    &.asc .asc {
      display: initial;
    }

    &.desc .desc {
      display: initial;
    }
  }

  select {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    box-shadow: none;
    box-sizing: content-box;
    display: block;
    font-size: 16px;
    height: 3rem;
    margin: 0 0 8px 0;
    outline: none;
    padding: 0;
    transition: box-shadow 0.3s, border 0.3s;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .card-action {
    &::after {
      clear: both;
      content: '';
      display: block;
    }
  }

  p.MuiFormHelperText-root {
    display: none;

    &.Mui-focused, &.Mui-error {
      display: block;
    }
  }
}

@media only screen and (max-width: 450px) {
  .app {
    .btn {
      display: inline-block;
      position: relative;
      text-indent: -9999px;
      width: 45px;
    }

    .btn > * {
      left: 0;
      position: absolute;
      text-indent: 0;
      top: 0;
    }

    .btn > svg {
      margin-left: 12px;
      margin-right: 0;
    }

    .responsive-table {
      thead > tr {
        margin-top: -13px;
        position: absolute;
        right: -7px;
      }

      tbody > tr {
        width: 100%;
      }
    }
  }
}

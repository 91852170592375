@import "../vars";

.form-check {
  input[type="checkbox"].form-check-input {
    width: 20px;
    height: 20px;

    &:checked {
      background-color: $primary;
      border-color: $primary;
    }

    &:hover {
      border-color: $primary;
    }
  }

  input[type="checkbox"], label {
    cursor: pointer;
  }

  label {
    padding: 1px 0 0 0;
  }
}
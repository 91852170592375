.filter-able-grid {
  ul {
    font-size: 0;
    padding: 0;
    margin: 0;

    > li {
      font-size: 16px;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }
}

.top-bar {
  background: #fff;
  border-bottom: 1px solid #f2f4f9;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  height: 56px;
  line-height: 56px;
  position: fixed;
  top: 0;
  width: calc(100% - 240px);
  z-index: 800;
  transition: all 300ms ease;



  &::after {
    clear: both;
    content: "";
    display: block;
  }

  .logo {
    padding: 2px;

    img {
      height: 52px;
      width: auto;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .top-bar {
    width: calc(100% - 70px);
  }
}

@media only screen and (min-width: 601px) {
  .top-bar {
    height: 64px;
    line-height: 64px;

    .logo {
      img {
        height: 60px;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .top-bar {
    right: 0;
    left: 0;
    width: auto !important;
    //margin-left: 70px;
    //width: calc(100% - 70px);
  }
}

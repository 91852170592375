@import "./../vars";

.form-input-field-container {
  .form-control.flatpickr-input[readonly] {
    background-color: #fff;
  }
}

.flatpickr-calendar {
  &.open.arrowBottom:after {
    border-top-color: $primary;
  }

  .flatpickr-months {
    .flatpickr-month {
      background-color: $primary;

      .flatpickr-monthDropdown-months {
        background-color: $primary;
      }
    }
  }

  .flatpickr-weekdays {
    background-color: $primary;

    .flatpickr-weekday {
      color: #fff;
      background-color: $primary;
    }
  }

  .flatpickr-day.selected {
    background-color: $primary;
    border-color: $primary;

    &:hover {
      background-color: $primary;
      border-color: $primary;
    }
  }
}
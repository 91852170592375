@import "../vars";

.footer {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;

  p {
    color: $infoHelp;
    cursor: default;
    font-size: 10px;

    span {
      float: right;
    }
  }
}
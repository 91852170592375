@import "../vars";

.btn {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
  padding: 3px 10px;
  outline: none !important;
  box-shadow: none !important;
  text-indent: 0;

  &[disabled] {
    opacity: 0.3;
  }

  .table-button {
    margin: 8px 0 -5px 0;
  }

  svg{
    margin: 0 5px 0 0 !important;
    position: relative;
  }

  &.fullwidth {
    width: 100%;
  }

  .action-loader {
    width: 15px;
    height: 15px;
    vertical-align: top;
    margin: 2px 10px 0 0 !important;
    color: #fff;
    fill: #ffff;
  }

  &.small {
    padding: 0 5px;
    font-size: 10px;

    .action-loader {
      width: 12px;
      height: 12px;
      margin: 1px 5px 0 0 !important;
    }
  }

  &.large {
    padding: 5px 15px;
    font-size: 16px;
  }

  &.xxl {
    padding: 5px 15px;
    font-size: 20px;

    svg {
      vertical-align: top;
      margin-top: 4px !important;
    }
  }

  &.btn-danger {
    border-color: $error;
    background-color: $error;
    color: #fff;

    &:hover {
      border-color: $errorDark !important;
      background-color: $errorDark !important;
    }
  }

  &.btn-success {
    border-color: $success;
    background-color: $success;
    color: #fff;

    &:hover {
      background-color: $successLight;
    }
  }

  &.btn-warning {
    border-color: $warning;
    background-color: $warning;
    color: #fff;

    &:hover {
      background-color: $warningDark;
    }
  }

  &.btn-primary {

    &:disabled {
      opacity: 0.5;
      background: $primary;
      color: #fff;
      border-color: $primary;
    }
    &.outlined, &.button-outlined {
      background: transparent;
      color: $primary;

      &.btn-danger {
        color: $error;
      }

      &:hover {
        background: $primary;
        color: #fff;
      }
    }

    &:hover {
      background: $primaryDark;
      border: 1px solid $primaryDark;
    }
  }
}

@media (max-width: $mobileResolution) {
  .btn.normal {
    padding: 6px 15px;
    font-size: 18px;
  }
}
@import "../vars";

.table-container {
  .cell-checkbox, .cell-collapse {
      width: 20px;
      padding-right: 0;
      display: inline-block;
      vertical-align: top;
      margin: 0 5px 0 0;
      top: -2px;
    position: relative;

      .form-check {
        margin: 0;
      }
  }

  .table-loading-container {
    text-align: center;
    padding: 50px 0;

    .table-spinner {
      font-size: 35px;


    }
  }

  table {
    table-layout: fixed;
    th, td {
      .tooltip-trigger {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        vertical-align: top;
      }
    }
  }

  &.rows-3 {
    overflow-y: auto;
    max-height: 205px;

    table {
      width: calc(100% - 15px);
    }
  }

  &.rows-4 {
    overflow-y: auto;
    max-height: 255px;

    table {
      width: calc(100% - 15px);
    }
  }

  &.rows-5 {
    overflow-y: auto;
    max-height: 305px;

    table {
      width: calc(100% - 15px);
    }
  }

  .cell-collapse {
    top: 3px;
  }

  .row-group-title {
    display: inline-block;
    margin: 2px 0 0 0;
  }

  .search-container {
    .current-filter {
      cursor: default;
      color: #666;
      padding: 1px 5px 0 2px;
      vertical-align: top;
      display: inline-block;
    }
    .filter-tag {
      border: 1px solid $primary;
      color: $primary;
      font-size: 10px;
      cursor: default;
      padding: 0 5px;
      margin: 3px 5px 0 0;
      display: inline-block;
      border-radius: 10px;
      vertical-align: top;

      span {
        vertical-align: top;
        padding: 1px 0 0 0;
        display: inline-block;
      }

      .remove-filter {
        font-size: 14px;
        margin: 0 0 0 5px;
        display: inline-block;
        cursor: pointer;

        &:hover {
          color: $primaryDark
        }
      }
    }

    .collapse-filter {
      color: $primary;
      padding: 1px 5px;
      margin: 2px 0 0 1px;
      cursor: pointer;
      font-size: 12px;
      vertical-align: bottom;

      &:hover {
        color: $primaryDark;
      }
    }
  }
}

table {
  margin: 14px 0 0 0;

  .no-table-cell-data {
    color: $inputHelp;
    cursor: default;
    font-weight: normal;
    font-size: 12px;
  }

  .table-row-group {
    border-top: 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: $primary;
    cursor: default;

    td {
      border-color: transparent;
      padding-bottom: 0;
    }

    &:hover {
      td {
        color: $primary;
        box-shadow: none !important;
      }
    }
  }

  th.table-header-cell {
    color: $primary;
    cursor: default;

    .cell-checkbox {
      top: -5px;
    }
  }

  tr.data-collapse {
    --bs-table-accent-bg: #fff !important;
    display: none;

    .check-able-collapse {
      padding: 0 0 0 30px;
    }

    .data-collapse-container {
      margin: 0 0 0 27px;
      width: calc(100% - 27px)
    }
  }

  tr {
    td.offset-cell {
      border-bottom-color: transparent;
      --bs-table-accent-bg: transparent !important;
    }
  }

  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }

    &.disabled-row {
      opacity: 0.5;

      * {
        cursor: default;
      }
    }

    &.data-highlight {
      font-weight: bold;
    }

    &.first-cell-offset {
      td:first-child {
        padding-left: 50px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          background-color: #fff;
          height: 1px;
          width: 40px;
        }
      }
    }

    &.last-group-row {
      td:first-child {
        &:before {
          display: none;
        }
      }
    }

    &.expand {
      .collapse-item {
        transform: rotate(90deg);
      }

      td {
        border-bottom-color: transparent;
      }

      + tr {
        display: table-row;
      }
    }

    td {
      small {
        font-size: 8px;
        color: $primary
      }

      .highlight-match {
        color: $primary;
      }

      .collapse-item {
        color: $inputHelp;
        cursor: pointer;
        transition: transform linear 0.2s;

        &:hover {
          color: $primary;
        }
      }
    }

    .hover-visible {
      opacity: 0;
    }

    .column-sort {
      font-size: 20px;
      margin: -11px 0 -8px 10px;
      cursor: pointer;

      &:hover {
        color: $primaryDark;
      }
    }

    &:hover {
      .hover-visible {
        opacity: 1;
      }
    }
  }

  .list-item-actions {
    margin: 0;
    padding: 0;
    //text-align: right;

    li {
      list-style-type: none;
      display: inline-block;
      padding: 0;
      margin: 0 0 0 5px;
      vertical-align: top;

      a {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.table-empty {
  margin: 25px 0 0 0;
}

.page-item-range {
  font-size: 12px;
  float: right;
  margin: 19px 10px 0 0;
  cursor: default;
}

.pagination-limit {
  width: 100px;
  float: right;
  margin: 15px 0 0 10px;
  border-color: #dee2e6;

  .select__control {
    min-height: 0;
    height: 24px; //32px;
  }

  .select__menu {
    position: absolute;
    top: auto;
    bottom: 26px;
  }

  .select__indicators {
    height: 24px;
  }

  .select__value-container {
    font-size: 12px;
    padding-top: 0;
    margin-top:0;

    .select__single-value {
      color: $primary;
    }
  }
}

nav {
  float: right;
  margin: 15px 0 0 0;

  ul.table-pagination {
    margin: 0;

    .active {
      span.page-link {
        color: #fff;
        border: 1px solid $primary;
        background-color: $primary;
      }
    }

    .page-link {
      cursor: pointer;
      color: $primary;

      padding: 3px 8px;
      font-size: 12px;
    }

    .table-pagination-item-more-icon {
      .page-link {
        background: #fff !important;
        position: relative;
        cursor: default;
      }
    }
  }
}

th, td {
  &.cell-align-right {
    text-align: right;
  }

  &.cell-align-center {
    text-align: center;
  }
}

@media (max-width: $mobileResolution) {
  .table-container {
    .table {
      display: block;

      tbody {
        display: block;
        tr {
          position: relative;
          display: block;
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
          border-radius: 5px;
          margin: 0 -13px 10px -13px;
          padding: 5px 0 0 10px;
          overflow: hidden;
          background: #fff;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            border: 1px solid transparent;
            border-radius: 5px;
            transition: border 0.25s ease-out;
          }

          &.row-selected {
            &:before {
              border: 1px solid $primary;
            }
          }

          &:hover {
            background-color: transparent;
            --bs-table-accent-bg: transparent !important;
            td {
              background-color: transparent;
            }
          }

          &.mobile-collapse-expand {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99999;
            padding: 0;
            background-color: rgba(0,0,0,.3);
            margin: 0;

            td {
              box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
              margin: 15px 10px;
              width: calc(100% - 30px);
              background-color: #fff;
              border-radius: 5px;
              padding: 5px;
              height: calc(100% - 30px);
              box-sizing: content-box;

              .data-collapse-container {
                margin: 0;
                padding: 0;
                width: 100%;
                height: calc(100% - 15px);
              }
            }
          }

          td {
            position: relative;
            display: inline-block;
            width: 100%;
            border: 0;
            padding-left: 0;

            .collapse-close {
              position: absolute;
              right: 8px;
              top: 8px;
              color: $inputHelp;
              font-size: 20px;
              z-index: 90000;
            }

            .cell-checkbox {
              z-index: 1000;
              position: absolute;
              top: -8px;
              left: 50%;
              transform: translate(-50%, 0);
              border-radius: 0 0 5px 5px;
              display: inline-block;
              text-align: center;
              font-weight: normal;
              font-size: 12px;
              padding: 0;
              line-height: 19px;
              width: auto;
              overflow: hidden;

              input {
                display: none;
                &:checked {
                  + label {
                    background-color: $primary;
                    color: #fff;
                    border: 1px solid $primaryDark;
                  }
                }
              }

              label {
                box-shadow: inset 0px -4px 10px -3px rgba(0, 0, 0, 0.24);
                padding: 2px 10px 0 10px;
                border-radius: 0 0 5px 5px;
                line-height: 16px;
                background-color: $warning;
                color: #fff;
                border: 1px solid $warningDark;
                text-transform: uppercase;
                letter-spacing: 5px;
              }

            }

            &.mobile-checkbox {
              padding: 0;
            }

            &.mobile-collapse {
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              color: #fff;
              background-color: $primary;
              writing-mode: vertical-rl;
              z-index: 5;
              width: 29px;
              min-width: 0;
              padding: 2px;
              text-transform: uppercase;
              font-size: 18px;
              //box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
              letter-spacing: 5px;
              text-align: center;
              font-weight: normal;
              box-shadow: inset 10px 0px 15px -10px #000000;

              svg:first-child {
                transform: rotate(180deg);
                margin: 0 5px 10px 0;
              }

              span {
                vertical-align: top;
              }

              svg:last-child {
                transform: rotate(180deg);
                margin: 10px 5px 0 0;
              }
            }

            .table-data-headline {
              font-size: 12px;
              position: absolute;
              top: 0;
              left: 0;
              color: $primary
            }

            &.mobile-td-11 { width:  91.66666%}
            &.mobile-td-10 { width:  83.33333%}
            &.mobile-td-9 { width:  74.999999997%}
            &.mobile-td-8 { width:  66.666666664%}
            &.mobile-td-7 { width:  58.333333331%}
            &.mobile-td-6 { width:  49.999999998%}
            &.mobile-td-5 { width:  41.666666665%}
            &.mobile-td-4 { width:  33.333333332%}
            &.mobile-td-3 { width:  24.999999999%}
            &.mobile-td-2 { width:  16.666666666%}
            &.mobile-td-1 { width:  8.333333333%}
          }

          ul.list-item-actions {
            margin: -20px 0 10px 0;

            li {
              position: relative;

              .action-label {
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 10px;
                font-weight: normal;
                color: $inputHelp
              }

              svg {
                margin: 3px 10px;
                width: 40px;
                height: 40px;
              }

            }
          }
        }
      }
    }
  }
}
@import "vars";

//TODO remove only page-content style if system upgrade final to v3 version
.page-content {
  margin: 5px 15px 25px 10px;
}

@media (max-width: $mobileResolution) {
  .page-content {
    margin: 15px 0 0 0;

    > .row {
      margin: 0;
    }

    form {
      > .row {
        margin: 0;
      }
    }

    .card {
      .card-body {
        padding: 10px 5px;
      }
    }
  }


}
.modal-content-box {
  .contract-required-text {
    padding: 15px 30px;
  }

  .contract-required-link-container {
    text-align: center;
    margin: 25px 0 0 0;
  }
}

.contract-required-link {
  cursor: pointer;
  &.missing-contract {
    font-size: 16px;

    svg {
      color: $warning;

      &:hover {
        color: darken($warning, 20%)
      }
    }
  }
}

.contract-required-btn {
  &.btn {
    svg {
      font-size: 16px;
      color: #fff;
      margin: -4px 0 0 5px !important;

      &:hover {
        color: #fff
      }
    }
  }
}
@import "../vars";

.last-change {
  > span {
    color: $infoHelp;
    display: block;
    font-size: 12px;
    cursor: default;
  }

  .no-data {
    color: $infoHelp !important;
    display: inline-block;
    height: 20px;
    font-size: 12px !important;
  }

  &.last-change-normal {
    > span {
      color: #000;
      display: block;
      font-size: 14px;
      cursor: default;
    }
  }

  &.right {
    text-align: right;
  }
}
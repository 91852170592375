// blue from dark to light
$blue1: #00334e;
$blue3: #5588a3;
$gray4: #f2f4f9;
$gray5: #fcfcfc;
$rot1: #762832;
$red2: #5e2028;

.app, .login-wrap {
  .bgBlue1 {
    background-color: $blue1;
  }

  .bgBlue3 {
    background-color: $blue3;
  }

  .blue1 {
    color: $blue1;
  }

  .blue3 {
    color: $blue3;
  }

  .input-field > label {
    color: $blue3;
  }

  .btn.bgBlue1:hover {
    background-color: $blue3;
  }

  .btn.bgBlue3:hover {
    background-color: $blue1;
  }

  .btn.flat.blue3 {
    background-color: transparent;
    border: 1px solid $blue3;
  }
}

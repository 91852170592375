.action-loader-container {
  display: inline-block;
  cursor: default;

  .action-loader {
    width: 24px;
    height: 24px;
    padding: 3px;
    font-size: 1.125rem;
    vertical-align: top;
    cursor: default;
  }

  svg {
    width: 24px;
    height: 24px;
    padding: 3px;
    font-size: 1.125rem;
    vertical-align: top;
    cursor: pointer !important;
  }
}
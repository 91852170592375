@import "../vars";

.tooltip-trigger {
  display: inline-block;

  &.fullwidth {
    width: 100%;
  }
}

.place-top, .place-left, .place-right, .place-bottom {
  &.__react_component_tooltip {
    font-weight: normal;
    border-color: $primary;
    padding: 4px 10px;
    text-transform: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
}

.place-top.__react_component_tooltip {
  &:before {
    border-top-color: $primary;
  }
}

.place-left.__react_component_tooltip {
  &:before {
    border-left-color: $primary;
  }
}

.place-right.__react_component_tooltip {
  &:before {
    border-right-color: $primary;
  }
}

.place-bottom.__react_component_tooltip {
  &:before {
    border-bottom-color: $primary;
  }
}

.hide-arrow {
  &:before, &:after {
    display: none;
  }
}
@import "../vars";

.content-container {
  .action-bar {
    display: inline-block;
    float: right;

    > * {
      background: $primary;
      color: #fff;
      border-radius: 4px;
      border: 1px solid $primary;
      width: 30px;
      height: 30px;
      display: inline-block;
      padding: 5px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: $primaryDark;
      }
    }
  }
}
//V3 sidebar helper in V2
.sidebar .sidebar-body {
  max-height: calc(100% - 60px);
  position: relative;
  border-right: 1px solid #e9ecef;
  height: 100%;
  -webkit-box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
  background: #fff;
}
.sidebar .sidebar-body .nav {
  display: flex;
  flex-direction: column;
  padding: 25px 25px 50px 25px;
}
.sidebar .sidebar-body .nav .nav-item {
  position: relative;
}
.sidebar .sidebar-body .nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  padding: 0;
  height: 32px;
  white-space: nowrap;
  color: #000;
}
.sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
  width: 16px;
  height: 16px;
  fill: rgba(233, 236, 239, 0.21);
  position: absolute;
  color: inherit;
}
.sidebar .sidebar-body .nav .nav-item .nav-link .link-title {
  margin-left: 30px;
  font-size: 14px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow {
  width: 14px;
  height: 14px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: inherit;
}
.sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow,
.sidebar .sidebar-body .nav .nav-item .nav-link .link-icon,
.sidebar .sidebar-body .nav .nav-item .nav-link .link-title {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar .sidebar-body .nav .nav-item .nav-link[aria-expanded='true'] {
  color: #6571ff;
}
.sidebar .sidebar-body .nav .nav-item .nav-link[aria-expanded='true'] .link-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}
.sidebar .sidebar-body .nav .nav-item.nav-category {
  color: #7987a1;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  height: 15px;
}
.sidebar .sidebar-body .nav .nav-item.nav-category:not(:first-child) {
  margin-top: 20px;
}
.sidebar .sidebar-body .nav .nav-item:hover .nav-link {
  color: #6571ff;
}
.sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-title {
  margin-left: 31px;
}
.sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-icon {
  color: #6571ff;
  fill: rgba(239, 243, 255, 0.5);
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link {
  color: #6571ff;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link::before {
  content: '';
  width: 3px;
  height: 26px;
  background: #6571ff;
  position: absolute;
  left: -25px;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link .link-icon {
  fill: rgba(239, 243, 255, 0.5);
  color: #6571ff;
}
.sidebar .sidebar-body .nav.sub-menu {
  padding: 0 0 15px 33px;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item {
  position: relative;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link {
  height: 25px;
  color: #000;
  font-size: 13px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link::before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: 0 0;
  border: 1px solid #aeb9c4;
  position: absolute;
  left: -29px;
  top: 10px;
  -webkit-transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link.active {
  color: #6571ff;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item .nav-link.active::before {
  border: 1px solid #6571ff;
  background: #6571ff;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item:hover .nav-link {
  color: #6571ff;
  margin-left: 3px;
}
.sidebar .sidebar-body .nav.sub-menu .nav-item:hover .nav-link::before {
  border: 1px solid #6571ff;
  background: #6571ff;
}
@media (max-width: 991px) {
  .sidebar {
    z-index: 999;
    margin-left: -240px;
    visibility: hidden;
  }
  .sidebar-open .sidebar {
    margin-left: 0;
    visibility: visible;
  }
  .sidebar .sidebar-body .nav .nav-item {
    width: auto;
  }
  .sidebar .sidebar-body .nav .nav-item .nav-link .link-icon {
    -webkit-transition: none;
    transition: none;
    margin: 0;
  }
}
.sidebar-dark .sidebar .sidebar-header {
  background: #0c1427;
  border-bottom: 1px solid rgba(233, 236, 239, 0.1);
  border-right: 1px solid rgba(233, 236, 239, 0.1);
}
.sidebar-dark .sidebar .sidebar-header .sidebar-brand {
  color: #e9ecef;
}
.sidebar-dark .sidebar .sidebar-header .sidebar-toggler span {
  background: #7987a1;
}
.sidebar-dark .sidebar .sidebar-body {
  background: #0c1427;
  border-right: 1px solid rgba(233, 236, 239, 0.1);
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item.nav-category {
  color: #fff;
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item .nav-link {
  color: #7987a1;
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item .nav-link svg {
  fill: none;
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item .nav-link[aria-expanded='true'] {
  color: #6571ff;
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item.active .nav-link svg,
.sidebar-dark .sidebar .sidebar-body .nav .nav-item:hover .nav-link svg {
  fill: rgba(101, 113, 255, 0.2);
}
.sidebar-dark .sidebar .sidebar-body .nav .nav-item.active .nav-link .link-title,
.sidebar-dark .sidebar .sidebar-body .nav .nav-item:hover .nav-link .link-title {
  color: #6571ff;
}
.settings-sidebar {
  position: fixed;
  right: -232px;
  top: 130px;
  width: 232px;
  background: #fff;
  -webkit-box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  z-index: 999;
  border-radius: 0 0 0 4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.settings-open .settings-sidebar {
  right: 0;
}
.settings-sidebar .sidebar-body {
  position: relative;
  padding: 18px;
}
.settings-sidebar .sidebar-body .settings-sidebar-toggler {
  position: absolute;
  left: -44px;
  top: 0;
  padding: 12px;
  border-radius: 4px 0 0 4px;
  background: #fff;
  -webkit-box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
  box-shadow: -3px 0 10px 0 rgba(183, 192, 206, 0.2);
}
.settings-sidebar .sidebar-body .settings-sidebar-toggler svg {
  width: 20px;
  height: 20px;
  color: #7987a1;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item {
  position: relative;
  display: block;
  margin-bottom: 19px;
  border-radius: 6px;
  border: 3px solid #e9ecef;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(101, 113, 255, 0);
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item:last-child {
  margin-bottom: 0;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item.active {
  border: 3px solid #b2b8ff;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item img {
  width: 100%;
  border-radius: 3px;
}
.settings-sidebar .sidebar-body .theme-wrapper .theme-item:hover::after {
  background: rgba(101, 113, 255, 0.2);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.sidebar-folded .sidebar .sidebar-header {
  width: 70px;
}
.sidebar-folded .sidebar .sidebar-header .sidebar-brand {
  display: none;
}
.sidebar-folded .page-wrapper {
  width: calc(100% - 70px);
  margin-left: 70px;
}
.sidebar-folded .page-wrapper .navbar {
  width: calc(100% - 70px);
  left: 70px;
  right: 0;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar {
  width: 70px;
  height: 0;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-header {
  width: 70px;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-header .sidebar-brand {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav .nav-item .nav-link .link-arrow,
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav .nav-item .nav-link .link-title {
  visibility: hidden;
  opacity: 0;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav .nav-item.nav-category {
  visibility: hidden;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav .nav-item.nav-category::before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #7987a1;
  position: absolute;
  top: 5px;
  left: 6px;
  visibility: visible;
}
.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .nav.sub-menu {
  display: none;
}
@media (max-width: 991px) {
  .settings-open .main-wrapper::before,
  .sidebar-open .main-wrapper::before {
    content: '';
    width: 100vw;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    -webkit-transition: all 3s ease;
    transition: all 3s ease;
    z-index: 980;
  }
}

.sidebar-header {
  img {
    max-height: 60px;
  }
}

.sidebar-folded {
  &.open-sidebar-folded {
    .sidebar-header {
      img {
        display: inline-block;
      }
    }
  }

  .sidebar-header {
    img {
      display: none;
    }
  }

  .sidebar-body {
    width: initial !important;
  }

  .top-bar {
    left: 0;
    width: calc(100% - 70px);
  }

  .app {
    padding-left: 70px;
  }
}

.top-bar {
  height: 60px;
  border-bottom: 1px solid #e9ecef;
}

@media (max-width: 601px) {
  .sidebar-folded:not(.open-sidebar-folded) {
    .v3 .ps {
      display: none;
    }

    .app {
      padding-left: 0;
    }
  }
}
@import "../vars";

input {
  + small.input-description {
    color: $inputHelp;
    padding: 0 0 0 10px;
  }
}

input[type="text"], input[type="password"], input[type="email"], input[type="number"] {
  box-sizing: border-box;

  &:hover {
    border-color: $primary;
  }
}

.form-input-field-container {
  position: relative;

  .form-label {
    color: rgba(0, 0, 0, 0.87)
  }

  .react-autocomplete-input {
    margin-top: -1px;
    padding: 0;
    border-radius: 3px;
    border-color: $primary;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    top: initial !important;
    left: initial !important;
    width: 100%;
    overflow: auto;
    max-height: 250px;

    li.active {
      background-color: $primary;
    }
  }
}

.readonly-data {
  padding: 0.669rem 0.8rem 0.469rem 0.8rem;
  color: #444;

  span {
    color: gray;
    cursor: default;
    font-weight: normal;
    font-size: 12px;
  }
}

datalist {
  position: absolute;
  max-height: 20em;
  border: 0 none;
  overflow-x: hidden;
  overflow-y: auto;
}

datalist option {
  font-size: 0.8em;
  padding: 0.3em 1em;
  background-color: #ccc;
  cursor: pointer;
}

datalist option:hover, datalist option:focus {
  color: #fff;
  background-color: #036;
  outline: 0 none;
}
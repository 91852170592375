@import "../vars";

.action-loader-container {
  .icon-color-danger {
    color: $error;

    &:hover {
      color: $errorDark;
    }
  }

  .icon-color-primary {
    color: $primary;
    fill: $primary;

    &:hover {
      color: $primaryDark;
      fill: $primaryDark;
    }
  }
}

.no-min-width {
  width: auto !important;
}

.icon-spinning {
  font-size: 35px;
  animation: icon-spinning-animation 5s linear infinite;
  fill: $primary;
  stroke: $primary;
}

@keyframes icon-spinning-animation {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(359deg)
  }
}
@import "../vars";

.loading-data {
  position: relative;
  input.form-control[readonly] {
    background-color: #fff;
    cursor: default;
  }

  .loader {
    position: absolute;
    top: 7px;
    left: 9px;
    font-size: 25px;
  }

  .loading-text {
    position: absolute;
    top: 7px;
    left: 42px;
    font-size: 18px;
  }
}

.select__control {
  .selection-item {
    .option-help {
      display: none;
    }
  }
}


.option-help {
  small {
    color: $infoHelp;
  }
}

.form-select {
  padding: 0;
  height: initial;
  box-sizing: border-box;
  cursor: pointer;

  > .select__control {
    border: none;
    cursor: pointer;

    &:hover {
      .select__indicator {
        color: $primary;
      }
    }
  }

  &:hover {
    border-color: $primary;

    .select__indicator-separator {
      background-color: $primary;
    }
  }

  > .select__indicators {
    background: #f00;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;
  }

  .select__menu {
    background: #fff;
    margin: 0;
    cursor: pointer;
    border: 1px solid $primaryLight;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;

    .select__menu-list {
      background: transparent;

      .select__option {
        background: transparent;
        color: #000;
        cursor: pointer;
      }

      .selection-item {
        &:hover {
          background: $primary;
          color: #fff;
          margin: -8px -12px;
          padding: 8px 12px;

          * {
            color: #fff;
          }
        }
      }

      .select__option:last-child {
        .create-action {
          border: 1px solid $success;
          margin: 0px -8px 0 -8px;
          border-radius: 5px;
          color: $successDark !important;
          padding: 5px 10px 5px 10px !important;

          svg {
            font-size: 18px;
            vertical-align: top;
          }

          .create-info-group {
            position: relative;

            span {
              position: absolute;
              bottom: -16px;
              left: 0;
              font-size: 9px;
              display: inline-block;
              padding: 0 5px;
              border: 1px solid $success;
              border-radius: 3px;
              background-color: #fff;
              font-weight: normal;
            }
          }

          &:hover {
            border: 1px solid $successDark;
            background-color: $successDark;
            color: #fff !important;

            .create-info-group {
              span {
                border-color: transparent;
                background-color: $successDark;
                color: #fff;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px 2px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
              }
            }
          }
        }

        &:hover {
          background: transparent;
          color: #000;
        }
      }
    }
  }

  //&.has-placeholder {
  //  &.placeholder-selected {
  //    .select__single-value {
  //      color: rgb(175, 181, 189);
  //    }
  //  }
  //
  //  .select__menu {
  //    .select__menu-list {
  //      .select__option:first-child {
  //        color: rgb(175, 181, 189);
  //      }
  //    }
  //  }
  //}
}
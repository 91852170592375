@import "../vars";

.priced-products {
  .product-price {
    height: 28px;
  }

  .product-interval {
    height: 21px;
  }

  .product-description {
      height: 32px;
  }

  table {
    height: 138px;
  }
}

.form-card {
  padding: 0 0 35px 0;

  &.small-padding {
    padding: 0;
  }
}

.card {
  .card-body {
    position: relative;
    border-radius: 5px;

    .card-title {
      color: $primary;
      font-size: 18px;
      border-bottom: 1px solid $primary;
      //background: $primary;
      //border-radius: 5px;
      padding: 10px 15px 5px 15px;
      //color: #fff;
      //font-weight: bold;
      margin: 0 0 11px 0;
      cursor: default;
    }

    .card-actions {
      margin: 24px 0;



      button, a {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: $mobileResolution) {
  .container {
    padding: 0 12px;
  }
}
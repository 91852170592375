@import "../vars";

.link {
  color: $primary;

  &.button-outlined {
    background-color: #fff;
    color: $primary;

    &:hover {
      background-color: $primary;
      color:#fff;
    }
  }

  &.button {
    color: #fff;
    .link-title {
      color: #fff;
    }

    &:hover {
      background: $primaryDark;
      border: 1px solid $primaryDark;
    }
  }

  &.uncolored {
    color: #000 !important;
  }

  &.button-success {
    border-color: $success;
    background-color: $success;
    color: #fff;

    &:hover {
      background-color: $successLight;
    }
  }
}
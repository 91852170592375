.app {
  .MuiBreadcrumbs-ol {
    margin: 6px 0;
  }

  .breadcrumb {
    color: rgba(0, 0, 0, 0.87);

    &:last-child {
      color: rgba(0, 0, 0, 0.87);
    }

    &::before {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  nav.row {
    background-color: #fff;
    box-shadow: none;
  }
}

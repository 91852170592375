@import "./../vars";

.data-chip {
  display: inline-block;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 12px;
  cursor: default;
  margin: 0 -1px 0 0;
  box-shadow: rgba(183, 192, 206, 0.2) 0 0 10px 0;
  height: 22px;
  box-sizing: border-box;
  vertical-align: top;
  border-radius: 0.25rem;
  border: 1px solid $primary;

  &.primary {
    background: $primary;
    color: #fff;
  }

  &.secondary {
    background: $primaryLight;
    color: #fff;
  }

  &.small {
    margin: 2px 0 0 0;
    padding: 2px 4px;
    line-height: 13px;
    font-size: 10px;
    height: 18px;
  }

  &.left-connected {
    border-radius: 0 0.25rem 0.25rem 0;
  }

  &.right-connected {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  &.full-connected {
    border-radius: 0;
  }
}
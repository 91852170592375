@import "../vars";

.row.disabled {
  position: relative;
  opacity: 0.6;

  .un-clickable {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90000;
  }
}

.password-container {
    .password-icon {
      position: absolute;
      right: 50px;
      top: 37px;
      font-size: 20px;
      cursor: pointer;
      color: $inputHelp;

      &:hover {
        color: $primary;
      }
    }
}
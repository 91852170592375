@import "../vars";

.label-new {
  color: $primary;
  border-radius: 5px;
  border: 1px solid $primary;
  padding: 2px 5px;
  margin: 0 0 0 10px;
  cursor: default;
  font-size: 10px;
  font-weight: normal !important;

}